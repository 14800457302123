import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Tooltip,
  IconButton
} from '@mui/material'

import {
  useJsonAPIDelete,
} from 'hooks'
import {
  Edit,
  Trash
} from 'icons'
import {
  ConfirmDialog
} from 'components'
import {
  openDialog
} from 'utils-em'
import ExpertiseDialog from './ExpertiseDialog'

const ExpertiseActions = ({ expertise }) => {
  const { deleteOne } = useJsonAPIDelete({ showDefaultAlerts: true })
  const onEdit = () => {
    openDialog(<ExpertiseDialog expertise={expertise} />)
  }
  const onDelete = async () => {
    openDialog(
      <ConfirmDialog
        title="Delete Area of Expertise"
        description={`Are you sure you want to delete this area of expertise: ${expertise.name}?`}
        actions={[
          {
            name: 'Delete',
            action: async () => {
              await deleteOne({
                type: 'expertises',
                id: expertise.id
              })
            }
          }
        ]}
      />,
    )
  }

  // eslint-disable-next-line react/prop-types
  const TooltipButton = ({ title, Icon, onClick }) => (
    <Tooltip title={title}>
      <IconButton
        color="primary"
        onClick={onClick}
      >
        <Icon />
      </IconButton>
    </Tooltip>
  )

  return (
    <Box sx={{ display: 'flex', gap: 1 }}>
      <TooltipButton title="Edit" Icon={Edit} onClick={onEdit} />
      <TooltipButton title="Delete" Icon={Trash} onClick={onDelete} />
    </Box>
  )
}

ExpertiseActions.propTypes = {
  expertise: PropTypes.object.isRequired
}

export default ExpertiseActions
