import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import Button from '@mui/material/Button'
import { ValidatorForm } from 'react-material-ui-form-validator'
import ExpandMore from '@mui/icons-material/ExpandMore'
import {
  Checkbox,
  Chip,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Link,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableSortLabel,
  TextField,
} from '@mui/material'

import {
  constants,
  formatMonthYearForHumans,
  calculateCompanyTenure,
  compileFilters
} from 'utils-em'

import {
  AdvisorProfileDialog,
  AdvisorTooltips,
  OrganizationSelector,
  TablePaginationActions,
  TagPicker
} from 'components'

const AdvisorSearchList = ({
  advisorRequest,
  loadCompanies,
  companies,
  openDialog,
  companiesCount
}) => {
  const [sortDirection, setSortDirection] = useState('desc')
  const [sortColumn, setSortColumn] = useState('roles.dateEnded')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(constants.ADMIN_DEFAULT_ROWS_PER_PAGE)
  const [searchTerms, setSearchTerms] = useState({
    'roles__any.tags__any.name.region': [],
    'advisor.tags__any.name.deliverable': [],
    'advisor.tags__any.name.vendor-category': [],
    'roles__any.title': [],
    'roles__any.titleInput': '',
    organizationId: null,
  })

  const [isCurrent, setIsCurrent] = useState(false)
  const [initialLoad, setInitialLoad] = useState(false)
  const getSortColumn = () => `${sortDirection === 'desc' ? '-' : ''}${sortColumn}`

  useEffect(() => {
    if (advisorRequest) {
      if (advisorRequest.account.organizationId) {
        setSearchTerms((prevTerms) => ({
          ...prevTerms,
          organizationId: advisorRequest.account.organizationId
        }))
      } else {
        setSearchTerms((prevTerms) => ({
          ...prevTerms,
          orgName: advisorRequest.account.name
        }))
      }
    }
  }, [advisorRequest])

  useEffect(() => {
    const filters = compileFilters(searchTerms)
    loadCompanies(rowsPerPage, page + 1, getSortColumn(), filters).then(() => {
      setInitialLoad(true)
    })
  }, [rowsPerPage, page, sortDirection, sortColumn, searchTerms])

  const addTagFilter = (filterName) => (tag) => {
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      [filterName]: [...prevTerms[filterName], tag]
    }))
  }

  const removeTagFilter = (filterName) => (tag) => {
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      [filterName]: prevTerms[filterName].filter((oldTag) => oldTag.id !== tag.id)
    }))
  }

  const handleOrgChange = (newOrg) => {
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      organizationId: newOrg ? newOrg.id : null
    }))
  }

  const handleCurrentlyEmployedCheck = () => {
    setIsCurrent((prevState) => !prevState)
  }

  const handleSearchFilter = (name) => (event) => {
    const { value } = event.target
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      [name]: value
    }))
  }

  const handleChipDown = (name) => (event) => {
    if ((event.key === 'Tab' || event.key === 'Enter') && searchTerms[`${name}Input`]) {
      event.preventDefault()
      setSearchTerms((prevTerms) => ({
        ...prevTerms,
        [name]: [...prevTerms[name], prevTerms[`${name}Input`]],
        [`${name}Input`]: ''
      }))
    }
  }

  const removeTitle = (titleToRemove) => {
    setSearchTerms((prevTerms) => ({
      ...prevTerms,
      'roles__any.title': prevTerms['roles__any.title'].filter((title) => title !== titleToRemove)
    }))
  }

  const makeRequest = () => {
    const filters = compileFilters(searchTerms)
    loadCompanies(rowsPerPage, page + 1, getSortColumn(), filters, isCurrent)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    makeRequest()
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value)
    setPage(0)
    makeRequest()
  }

  const sortOnColumn = (column) => {
    const newSortDirection = sortColumn !== column || sortDirection === 'asc' ? 'desc' : 'asc'
    setSortColumn(column)
    setSortDirection(newSortDirection)
    makeRequest()
  }

  if (!initialLoad) return null
  return (
    <>
      <ValidatorForm onSubmit={makeRequest}>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            Filters
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Advisor Name"
                  value={searchTerms['advisor.fullName'] || ''}
                  onChange={handleSearchFilter('advisor.fullName')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Titles"
                  value={searchTerms['roles__any.titleInput'] || ''}
                  onChange={handleSearchFilter('roles__any.titleInput')}
                  onKeyDown={handleChipDown('roles__any.title')}
                  InputProps={{
                    startAdornment: (
                      (searchTerms['roles__any.title'] || []).map((title) => (
                        <Chip
                          key={title}
                          tabIndex={-1}
                          label={title}
                          onDelete={() => { removeTitle(title) }}
                        />
                      ))
                    )
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Advisor Location"
                  value={searchTerms['advisor.location'] || ''}
                  onChange={handleSearchFilter('advisor.location')}
                />
              </Grid>
              <Grid item xs={6}>
                <OrganizationSelector
                  organizationId={searchTerms.organizationId}
                  handleChange={(newOrg) => { handleOrgChange(newOrg) }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Organization (backup)"
                  placeholder="To be used if organization is not available"
                  value={searchTerms.orgName || ''}
                  onChange={handleSearchFilter('orgName')}
                />
              </Grid>
              <Grid item xs={6}>
                <TagPicker
                  label="Region"
                  type="region"
                  compact
                  addTag={addTagFilter('roles__any.tags__any.name.region')}
                  removeTag={removeTagFilter('roles__any.tags__any.name.region')}
                  selectedTags={searchTerms['roles__any.tags__any.name.region']}
                />
              </Grid>
              <Grid item xs={6}>
                <TagPicker
                  label="Vendor Category"
                  type="vendor-category"
                  compact
                  addTag={addTagFilter('advisor.tags__any.name.vendor-category')}
                  removeTag={removeTagFilter('advisor.tags__any.name.vendor-category')}
                  selectedTags={searchTerms['advisor.tags__any.name.vendor-category']}
                />
              </Grid>
              <Grid item xs={6}>
                <TagPicker
                  label="Deliverable"
                  type="deliverable"
                  compact
                  addTag={addTagFilter('advisor.tags__any.name.deliverable')}
                  removeTag={removeTagFilter('advisor.tags__any.name.deliverable')}
                  selectedTags={searchTerms['advisor.tags__any.name.deliverable']}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={isCurrent}
                      onChange={handleCurrentlyEmployedCheck}
                    />
                  )}
                  label="Currently Employed with Company"
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={makeRequest}
                >
                  FILTER
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Paper sx={{ mt: '0.5em', p: '1em' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    column="name"
                    active={sortColumn === 'advisor.fullName'}
                    direction={sortDirection}
                    onClick={() => sortOnColumn('advisor.fullName')}
                  >
                    Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Title
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    column="orgName"
                    active={sortColumn === 'orgName'}
                    direction={sortDirection}
                    onClick={() => sortOnColumn('orgName')}
                  >
                    Organization
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    column="dateEnded"
                    active={sortColumn === 'roles.dateEnded'}
                    direction={sortDirection}
                    onClick={() => sortOnColumn('roles.dateEnded')}
                  >
                    Departure Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  Tenure
                </TableCell>
                {advisorRequest
                  ? (
                    <>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </>
                    ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((company) => {
                // use the first role, since it's sorted by recency!
                const role = company.roles[0]
                return (
                  <TableRow key={company.id}>
                    <TableCell>
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                          openDialog(
                            <AdvisorProfileDialog
                              advisorId={parseInt(company.advisor.id, 10)}
                            />
                            , 'advisorProfile'
                          )
                        }}
                      >
                        {company.advisor.fullName}
                      </Link>
                      <AdvisorTooltips advisor={company.advisor} />
                    </TableCell>
                    <TableCell>
                      {role.title}
                    </TableCell>
                    <TableCell>
                      {company.orgName}
                    </TableCell>
                    <TableCell>
                      {formatMonthYearForHumans(role.dateEnded)}
                    </TableCell>
                    <TableCell>
                      {calculateCompanyTenure(company)}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={constants.ROWS_PER_PAGE_OPTIONS}
                  colSpan={8}
                  count={companiesCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </ValidatorForm>
    </>
  )
}

AdvisorSearchList.defaultProps = {
  advisorRequest: null,
  companies: [],
  companiesCount: 0
}

AdvisorSearchList.propTypes = {
  advisorRequest: PropTypes.object,
  companies: PropTypes.array,
  companiesCount: PropTypes.number,
  loadCompanies: PropTypes.func.isRequired,
  openDialog: PropTypes.func.isRequired
}

export default AdvisorSearchList
