import React from 'react'
import {
  Box,
  Button
} from '@mui/material'
import {
  JsonApiDataGrid
} from 'components'
import {
  openDialog
} from 'utils-em'
import ExpertiseActions from './components/ExpertiseActions'
import ExpertiseDialog from './components/ExpertiseDialog'

const ExpertiseTab = () => {
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 75,
      valueGetter: ({ row }) => row.id,
      hidden: true
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      valueGetter: ({ row }) => row.name,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      valueGetter: ({ row }) => row.description,
    },
    {
      field: 'actions',
      actions: true,
      headerName: 'Actions',
      disableExport: true,
      disableSort: true,
      disableFilter: true,
      valueGetter: ({ row }) => row.id,
      renderCell: ({ row }) => <ExpertiseActions expertise={row} />
    },
  ]
  return (
    <Box>
      <Button
        variant="contained"
        onClick={() => openDialog(<ExpertiseDialog />)}
        sx={{ mb: 1 }}
      >
        Create Area of Expertise
      </Button>
      <JsonApiDataGrid
        type="expertises"
        columns={columns}
        mode="server"
        jsonApiOptions={{
          sortBy: 'name',
        }}
        dependencies={[]}
      />
    </Box>
  )
}

export default ExpertiseTab
