import React, {
  useState,
  useEffect,
} from 'react'
import {
  useParams
} from 'react-router-dom'
import {
  TimezoneDropdown,
  IconTextCombo,
  ResponsiveDivider,
} from 'components'
import {
  Alert
} from 'icons'
import {
  TIMEZONE_MAP,
  formatDate,
  navigateTo,
  confirmDialog
} from 'utils-em'
import {
  Box,
  Button,
  Grid,
  ToggleButton,
  Typography
} from '@mui/material'
import moment from 'moment'
import {
  useEngagement,
  useJsonAPIUpsert
} from 'hooks'
import { useSelector } from 'react-redux'
import CallAvailability from '../CallAvailability'

const TimeSelectionStep = () => {
  const userId = useSelector(({ session }) => session.id)
  const { upsert } = useJsonAPIUpsert()
  const engagementId = parseInt(useParams().engagementId, 10)
  const {
    proposedTimeslotProposal,
    advisor,
    customerUser,
  } = useEngagement(engagementId)
  const { timeslots } = proposedTimeslotProposal
  const [timezone, setTimezone] = useState(proposedTimeslotProposal.tzName)

  useEffect(() => {
    if (timeslots && timezone) setTimeslotSelectionsByDay(groupTimeSlotsByDayLocal(timeslots, timezone))
  }, [timezone, timeslots?.count])

  const isAdvisor = parseInt(advisor?.id, 10) === userId
  const [timeslotSelectionsByDay, setTimeslotSelectionsByDay] = useState({})
  const groupTimeSlotsByDayLocal = (slots, currentTimezone) => {
    const groupedTimeslots = {}
    const localSlots = slots ?? []
    localSlots.forEach((slot) => {
      const day = moment(slot.startTime).tz(currentTimezone).startOf('day').format('YYYY-MM-DD')
      if (!groupedTimeslots[day]) {
        groupedTimeslots[day] = []
      }
      groupedTimeslots[day].push(slot)
    })
    return groupedTimeslots
  }

  const isCurrentUserSelectingTime = proposedTimeslotProposal.respondingUserId === userId

  const acceptTimeSlot = async (timeslot) => {
    confirmDialog({
      title: 'Accept call time',
      description: `Do you want to confirm your call for ${moment(timeslot.startTime).tz(timezone).format('MMMM D,')} ${moment(timeslot.startTime).tz(timezone).format('h:mm A')} - ${moment(timeslot.endTime).tz(timezone).format('h:mm A z')}?`,
      actions: [
        {
          name: 'Confirm',
          action: () => {
            upsert({
              type: 'timeslots',
              id: timeslot.id,
              isSelected: true,
              acceptProposal: true,
              riders: ['acceptProposal'],
              queryStringParams: { 'lazy[engagements]': 'step' }
            }).then(() => navigateTo(`/${isAdvisor ? 'a' : 'c'}/engagements/${engagementId}`))
          }
        }
      ]
    })
  }
  const allTimeslotsInPast = timeslots?.every((slot) => moment(slot.startTime).isBefore(moment()))
  if (allTimeslotsInPast) {
    navigateTo(`/${isAdvisor ? 'a' : 'c'}/engagements/${engagementId}#reschedule`)
  }
  const hasTimezoneDifference = customerUser?.timezone && advisor?.timezone && customerUser.timezone !== advisor.timezone
  if (!isCurrentUserSelectingTime) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
        <Typography variant="h3">{`${isAdvisor ? customerUser.firstName : advisor.firstName} is reviewing your availability`}</Typography>
        <Typography variant="body">Once they accept a call time (or send alternative times), you'll receive an email notification.</Typography>
        <ResponsiveDivider />
        <CallAvailability engagementId={engagementId} />
      </Box>
    )
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <Typography variant="h3">
        {isAdvisor ? `Thank you for accepting ${customerUser.firstName}'s proposal!` : `${advisor.firstName} has accepted your proposal!`}
      </Typography>
      <Typography variant="body">
        {`${isAdvisor ? customerUser.firstName : advisor.firstName} has provided their availability for a one hour call. Please select a slot from the following options.`}
      </Typography>
      <Box>
        <TimezoneDropdown
          initialTimezone={timezone}
          onTimezoneSelected={(e) => setTimezone(e)}
        />
        {hasTimezoneDifference && (
          <IconTextCombo
            icon={<Alert />}
            iconColor="neutral.darkGrey"
            text={`${customerUser.firstName} is in the ${TIMEZONE_MAP[customerUser.timezone]} (${moment().tz(advisor?.timezone).format('Z')}) time zone`}
            typographyColor="neutral.black"
          />
        )}
      </Box>
      {Object.keys(timeslotSelectionsByDay).map((day) => (
        <Box key={day} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box>
            <Typography variant="body1">{formatDate(new Date(`${day}T00:00:00`), { includeDayOfWeek: true })}</Typography>
          </Box>
          <Grid container spacing={2} direction="row">
            {timeslotSelectionsByDay[day].map((slot) => (
              <Grid item key={slot.startTime}>
                <ToggleButton value={slot.startTime} sx={{ color: 'brand.darkNavy', width: 170 }} onClick={() => acceptTimeSlot(slot)}><Typography variant="tinyBold">{`${moment(slot.startTime).tz(timezone).format('h:mm')} - ${moment(slot.endTime).tz(timezone).format('h:mm A')}`}</Typography></ToggleButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
      <Box sx={{ ml: -2 }}>
        <Button onClick={() => navigateTo(`/${isAdvisor ? 'a' : 'c'}/engagements/${engagementId}#reschedule`)}>None of these times work for me</Button>
      </Box>
    </Box>
  )
}

export default TimeSelectionStep
