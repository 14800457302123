import React from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
} from '@mui/material'

import {
  useJsonAPIUpsert,
} from 'hooks'
import {
  FormTextField
} from 'components'
import {
  closeDialog,
} from 'utils-em'

const ExpertiseDialog = ({ expertise }) => {
  const { upsert } = useJsonAPIUpsert({ showDefaultAlerts: true })
  const form = useForm({
    defaultValues: {
      name: expertise?.name || '',
      description: expertise?.description || ''
    }
  })

  const onSubmit = async (data) => {
    await upsert({
      type: 'expertises',
      id: expertise?.id,
      ...data
    })
    closeDialog()
  }

  return (
    <Dialog
      open
      sx={{ '& .MuiDialog-paper': { minWidth: 600 } }}
    >
      <DialogTitle>
        {expertise ? 'Edit Area of Expertise' : 'Create Area of Expertise'}
      </DialogTitle>
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormTextField
            name="name"
            label="Name"
            form={form}
            required
          />
          <FormTextField
            name="description"
            label="Description"
            form={form}
            required
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={closeDialog}>Cancel</Button>
          <Button variant="contained" type="submit">Save</Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

ExpertiseDialog.defaultProps = {
  expertise: null
}

ExpertiseDialog.propTypes = {
  expertise: PropTypes.object
}

export default ExpertiseDialog
