import moment from 'moment'
import {
  useSessionUser,
  useJsonAPISelectOne
} from 'hooks'
import {
  getAllScheduledCalls,
  constants,
  createFutureDate,
  getCustomerPostCallFeedback,
  getEmailRelayLink,
  getEngagementMostRelevantTimeslot,
  numberOfDaysBetweenDates,
  getMostRecentProposal,
  isEngagementCallToday
} from 'utils-em'

const useEngagement = (engagementId) => {
  const engagement = useJsonAPISelectOne('engagements', engagementId)
  const { id: userId, isCustomerUser, isAdvisor } = useSessionUser()

  if (!engagement) return { loaded: false }

  // relay
  const relayAddress = engagement && getEmailRelayLink(engagement)

  // timeslots
  const timeslots = getAllScheduledCalls(engagement)
  const relevantProposal = engagement && getMostRecentProposal(engagement)
  const relevantTimeslot = engagement && getEngagementMostRelevantTimeslot(engagement)
  const proposedTimeslotProposal = engagement?.relevantTimeslotProposals?.find((proposal) => proposal.status === 'PROPOSED')
  const isCallToday = isEngagementCallToday(engagement)
  const isCallInThirty = isCallToday && moment(relevantTimeslot.startTime).diff(moment(), 'minutes') <= 30

  // user
  const isPrimaryCustomerUser = engagement?.customerUserId === userId
  const isParticipant = !isAdvisor && !isPrimaryCustomerUser

  // feedback
  const customerFeedback = engagement && getCustomerPostCallFeedback(engagement)
  const advisorFeedback = engagement?.advisorSingleCallFeedback
  const isFeedbackComplete = Boolean(isCustomerUser ? customerFeedback : advisorFeedback)

  // proposal
  const proposalExpiryDate = engagement && createFutureDate(new Date(engagement.dateCreated), 0, 0, constants.ENGAGEMENT_PROPOSAL_EXPIRATION_TIME_IN_DAYS)
  const daysUntilProposalExpires = engagement && numberOfDaysBetweenDates(new Date(), proposalExpiryDate)

  // engagement steps/states
  const isCallProposed = engagement?.state === 'proposed'
  const isProposalExpired = engagement?.state === 'expired'
  const isProposalCanceled = engagement?.state === 'proposal-canceled'
  const isProposalRejected = engagement?.state === 'rejected'
  const isAdminCanceled = engagement?.state === 'canceled'
  const isActive = engagement?.state === 'active'
  const isClosed = engagement?.state === 'closed'
  const isInScheduling = isActive && ['SCHEDULING'].includes(engagement?.step)
  const isCallUpcoming = isActive && ['PREP_FOR_CALL', 'COMPLETE_CALL'].includes(engagement?.step)
  const isCallComplete = isActive && ['SUBMIT_FEEDBACK', 'FEEDBACK_SUBMITTED'].includes(engagement?.step)
  const isFeedbackRequired = !isFeedbackComplete && ['SUBMIT_FEEDBACK', 'FEEDBACK_SUBMITTED'].includes(engagement?.step)
  const isCanceled = isProposalCanceled || isAdminCanceled

  return {
    ...engagement,
    loaded: true,
    relevantProposal,
    relayAddress,

    // timeslots
    timeslots,
    relevantTimeslot,
    proposedTimeslotProposal,
    isCallToday,
    isCallInThirty,

    // user
    isPrimaryCustomerUser,
    isCustomerUser,
    isAdvisor,
    isParticipant,

    // proposal
    proposalExpiryDate,
    daysUntilProposalExpires,

    // feedback
    customerFeedback,
    advisorFeedback,
    isFeedbackComplete,

    // engagement steps
    isProposalCanceled,
    isProposalExpired,
    isProposalRejected,
    isCallProposed,
    isInScheduling,
    isCallUpcoming,
    isCallComplete,
    isFeedbackRequired,
    isCanceled,
    isActive,
    isClosed
  }
}

export default useEngagement
