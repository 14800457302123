import React from 'react'
import PropTypes from 'prop-types'

import {
  Divider,
} from '@mui/material'
import {
  useEngagement,
  useSessionUser
} from 'hooks'
import { ResponsiveContent } from 'components'

import ActivityLog from './components/ActivityLog'
import CallActionsSection from './components/CallActionsSection'
import CallDocumentsSection from './components/CallDocumentsSection'
import CallPrepSection from './components/CallPrepSection'
import TimeSelectionStep from './components/TimeSelectionStep'
import ProposalMessage from './components/ProposalMessage'
import CallAvailability from './components/CallAvailability'
import ProposalCanceled from './components/ProposalCanceled'
import ProposalExpired from './components/ProposalExpired'
import FinalizeEngagementSection from './components/FinalizeEngagementSection'

const EngagementContent = ({ engagementId }) => {
  const { isCustomerUser } = useSessionUser()
  const {
    isProposalCanceled,
    isProposalExpired,
    isCallProposed,
    isInScheduling,
    isCallUpcoming,
    isCallComplete,
    isClosed,
    isFeedbackRequired,
    assets,
  } = useEngagement(engagementId)

  const sections = (isCustomerUser ? [
    // cu sections
    {
      key: 'time-selection',
      Component: TimeSelectionStep,
      visible: isInScheduling
    },
    {
      key: 'proposal-canceled',
      Component: ProposalCanceled,
      visible: isProposalCanceled,
    },
    {
      key: 'proposal-expired',
      Component: ProposalExpired,
      visible: isProposalExpired,
    },
    {
      key: 'proposal-message',
      Component: ProposalMessage,
      visible: isCallProposed,
    },
    {
      key: 'call-availability',
      Component: CallAvailability,
      visible: isCallProposed
    },
    {
      key: 'call-actions',
      Component: CallActionsSection,
      visible: isCallUpcoming || isFeedbackRequired
    },
    {
      key: 'call-documents',
      Component: CallDocumentsSection,
      visible: isCallComplete && assets?.length
    },
    {
      key: 'call-prep',
      Component: CallPrepSection,
      visible: isCallUpcoming
    },
    {
      key: 'finalize-call',
      Component: FinalizeEngagementSection,
      visible: isCallComplete && !isClosed
    },
    {
      key: 'activity-log',
      Component: ActivityLog,
      visible: !isCallProposed
    }
  ] : [
    {
      key: 'proposal-canceled',
      Component: ProposalCanceled,
      visible: isProposalCanceled,
    },
    {
      key: 'time-selection',
      Component: TimeSelectionStep,
      visible: isInScheduling
    },
    {
      key: 'call-actions',
      Component: CallActionsSection,
      visible: isCallUpcoming || isFeedbackRequired
    },
    {
      key: 'call-prep',
      Component: CallPrepSection,
      visible: isCallUpcoming
    },
    {
      key: 'finalize-call',
      Component: FinalizeEngagementSection,
      visible: isCallComplete && !isClosed
    },
    {
      key: 'activity-log',
      Component: ActivityLog,
      visible: !isCallProposed
    }
  ]).filter(({ visible }) => visible)

  const activityLogExpandedByDefault = sections.length === 1

  return (
    <ResponsiveContent>
      {sections.map(({ key, Component }, index) => (
        <React.Fragment key={key}>
          <Component
            key={key}
            engagementId={engagementId}
            // if activity log is the only section rendered, expand it by default
            {...(key === 'activity-log' ? { expandedByDefault: activityLogExpandedByDefault } : {})}
          />
          {index < sections.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </ResponsiveContent>
  )
}

EngagementContent.propTypes = {
  engagementId: PropTypes.string.isRequired
}

export default EngagementContent
